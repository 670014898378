<template>
    <div class="cont config-cont">
       <!-- 面包屑 -->
       <el-row class="crumbs-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
             <el-breadcrumb-item>系统管理</el-breadcrumb-item>
             <el-breadcrumb-item>系统配置</el-breadcrumb-item>
          </el-breadcrumb>
       </el-row>
        <el-row>
            <el-col>
                <el-button class="bg-gradient" @click="submitConfig" style="float: right" type="primary">提交</el-button>
            </el-col>
        </el-row>
        <div class="det-box">
           <el-row class="title"><h6 class="font-18">文件上传设置</h6></el-row>
            <div class="box-next">
                <el-col :span="12">
                    <el-row v-for="(item, key) in formData.fileUpload" :key="key" :span="8" class="t-left l-height-40">
                        <div v-if="item.cfgCode === 'file-upload-port'?false:true" class="m-bottom-15" style="overflow: hidden">
                           <label>{{item.cfgName}}：</label>
                           <el-input class="width-370" v-model="item.content" placeholder="文件上传根目录"  clearable></el-input>
                        </div>
                    </el-row>
                </el-col>
                <el-col :span="12" v-if="formData.fileUpload.length !== 0">
                    <el-row align="top" :span="16" class="t-left l-height-40">
                        <label>{{formData.fileUpload[1].cfgName}}：</label>
                        <el-input class="width-370" v-model="formData.fileUpload[1].content" placeholder="端口"  clearable></el-input>
                    </el-row>
                </el-col>
            </div>
        </div>
        <div class="det-box">
           <el-row class="title"><h6 class="font-18">服务器邮箱设置</h6></el-row>
           <div class="box-next">
                <el-col :span="12">
                    <el-row class="t-left l-height-40">
                        <div class="m-bottom-15" style="overflow: hidden">
                           <label>SMTP服务器：</label>
                           <el-input class="width-370" v-model="formData.smtpHost" placeholder=""  clearable></el-input>
                        </div>
                    </el-row>
                    <el-row class="t-left l-height-40" v-for="(item, key) in formData.serMailbox" :key="key">
                        <div v-if="item.cfgCode === 'smtp-host'?false:true" class="m-bottom-15" style="overflow: hidden">
                           <label>{{item.cfgName}}：</label>
                           <el-input class="width-370" v-model="item.content" placeholder=""  clearable></el-input>
                        </div>
                    </el-row>
                </el-col>
                <el-col :span="12">
                    <el-row align="top" :span="16" class="t-left l-height-40">
                        <label>端口：</label>
                        <el-input class="width-370" v-model="formData.smtpPort" placeholder="端口"  clearable></el-input>
                    </el-row>
                </el-col>
            </div>
        </div>
        <div class="det-box">
           <el-row class="title"><h6 class="font-18">触发场景智能设备配置</h6></el-row>
           <el-transfer
                 filterable
                 :filter-method="filterMethod"
                 filter-placeholder="产品名称"
                 :titles="['设备列表', '已选设备']"
                 v-model="formData.checkDevices"
                 :data="devices">
           </el-transfer>
        </div>
    </div>
</template>

<script>
   import { urlObj  } from '@/api/interface'
   import { system } from '@/api/interface/system'
   import common from '@/common/js/common'
   export default {
      data(){
         return{
            formData: {
               fileUpload: [],    // 文件上传
               serMailbox: [],    // 服务器邮箱
               checkDevices: [],  // 被选中的设备列表
               smtpHost: '',      // SMTP服务器地址
               smtpPort: 0,       // SMTP服务器端口
            },
            modifyData: false,    // 数据修改状态
            devices: [],          // 产品设备列表
            update_success: '配置修改成功！',
            data_not_modified: '数据未经修改！'
         }
      },
      mounted() {
         this.getConfig()
         this.getCategoryList()
      },
      methods: {
         // 获取系统配置信息
         getConfig(){
            const url = system.getConfig
            this.$axios.post(url, {}).then(res => {
               if (res.success){
                  // 文件上传设置
                  let fileUpload = res.records[0].children
                  this.formData.fileUpload = fileUpload.map(item => ({cfgName: item.cfgName, content: item.content,cfgCode: item.cfgCode}))
                  // 服务器邮箱设置
                  let serMailbox = res.records[1].children
                  this.formData.serMailbox = serMailbox.map(item => ({cfgName: item.cfgName,content: item.content,cfgCode: item.cfgCode}))
                  const smtp = JSON.parse(this.formData.serMailbox[3].content)
                  this.formData.smtpHost = smtp.smtpHost
                  this.formData.smtpPort = smtp.smtpPort
                  // 触发场景智能设备配置
                  let checkDevices = res.records[2].content
                  this.formData.checkDevices = JSON.parse(checkDevices).typeCode
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取设备列表
         getCategoryList(){
            const url = urlObj.categoryList
            this.$axios.post(url, {}).then(res => {
               if (res.success){
                  let devices = res.records
                  this.devices = devices.map(item => ({label: item.name, key: item.code}))
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 搜索设备
         filterMethod: (val, item) => item.label.indexOf(val) > -1,
         // 提交系统配置信息
         submitConfig(){
            // 判断表单是否有被修改
            if (this.modifyData){
               this.$message({
                  showClose: true,
                  message: this.data_not_modified,
                  type: 'warning'
               })
               return
            }
            const keys = [
               'file-upload-host',
               'file-upload-port',
               'file-upload-path',
               'sender-name',
               'sender-password',
               'smtp-host',
               'protocol-type',
               'file-host',
               'scene-trigger-device'
            ]
            const value = [...this.formData.fileUpload, ...this.formData.serMailbox]
            let param = {}
            value.forEach(val => {
               keys.forEach(key => {
                  if (val.cfgCode === key && val.cfgCode === 'scene-trigger-device'){
                     param[key] = `{"{"typeCode": "${this.formData.checkDevices}"}`
                  }
                  if (val.cfgCode === key && val.cfgCode === 'smtp-host'){
                     param[key] = `{"smtpHost": "${this.formData.smtpHost}","smtpPort": ${this.formData.smtpPort}}`
                  }
                  if (val.cfgCode === key){
                     param[key] = val.content
                  }
               })
            })
            const url = system.updateConfig
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.update_success,
                     type: 'success'
                  })
               }
            }).catch(error => {
               console.log(error)
            })
         }
      },
      watch: {
         formData: {
            handler(newVal, oldVal){
               if (newVal === oldVal){
                  this.modifyData = true
               }
            },
            deep: true
         }
      }
   }
</script>


<style scoped lang="scss">
    .config-cont{
        .box-span{
            vertical-align: middle;
            margin-left: 10px;
        }
       .title{
          line-height: 50px; text-indent: 10px; margin-bottom: 30px;
       }
       .el-transfer{
          margin: 10px 0 0 80px;
       }
    }
    .det-box{
        /* border: 1px solid red; */
        background: white;
        padding: 10px 20px;
        margin-bottom: 10px;
        .box-next{
            width: 80%;
            overflow: hidden;
            /deep/ .el-input__inner{
                height: 40px;
                line-height: 40px;
            }
           label{ width: 220px; display: inline-block; text-align: right }
        }
        .next-button{
            text-align: center;
            .el-button{
                width: 120px;
                color: white;
                background: #1ABC9C;
            }
        }
        .box-info{
            width: 80%;
            margin: 0 auto;
            /deep/ .el-input__inner{
                height: 40px;
                line-height: 40px;
            }
            .rate-number{
                /deep/ .el-rate__icon{
                    height: 40px;
                    line-height: 40px;
                    font-size: 30px;
                }
            }
        }
        .box_list{
            .el-col-4{
                height: 50px;
                line-height: 50px;
                padding-left: 15px;
                border: 1px solid rgba(204, 204, 204, 1);
                border-width: 0 1px 1px 0;
                &:nth-child(6n){
                    border-right: 0;
                }
                &:nth-last-child(1),&:nth-last-child(2),&:nth-last-child(3),&:nth-last-child(4),&:nth-last-child(5),&:nth-last-child(6){
                    border-bottom: 0;
                }
            }
            .box-inline{
                display: inline-block;
                cursor: pointer;
            }
        }
        .box-bg{
            background: rgba(204, 204, 204, 0.3);
            border-bottom: 1px solid rgba(204, 204, 204, 1);
        }
        .box-border{
            border: 1px solid rgba(204, 204, 204, 1);
        }
    }
    .det-box::after{
        content: "";
        display: block;
        clear: both;
    }
</style>
